import { httpConfig } from "./config"
import axios from 'axios'


axios.defaults.baseURL = httpConfig.baseURL
axios.defaults.headers.post['Accept'] = httpConfig.headers.Accept
// axios.defaults.headers.post['Conten-Type'] = httpConfig.headers["Content-Type"]
axios.defaults.withCredentials = httpConfig.withCredentials


axios.interceptors.request.use(function (config) {
    const authToken = localStorage.getItem('accessToken')
    config.headers.Authorization = authToken ? `Bearer ${authToken}` : ''
    config.headers['Access-Control-Allow-Origin'] = ["https://app.xettle.io", "https://api.xettle.io", "https://web.xettle.io"]
    return config
})


export const http = () => {
    return axios
}