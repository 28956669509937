import validationSuite from '@src/assets/images/business/validation_suite.png'
import ocr from '@src/assets/images/business/ocr.png'
// import bbps from '@src/assets/images/business/bbps_logo.png'
import aeps from '@src/assets/images/business/aeps.png'
import bbps from '@src/assets/images/business/bbps_logo.png'
import recharge from '@src/assets/images/business/mobile.png'
import atm from '@src/assets/images/business/atm.png'

export const baseURL = 'https://dash.xettle.io'
export const oldDashUrl = 'https://dash.xettle.io'
export const NOCAPTCHA_SITEKEY = '6LcDSbQcAAAAANOKWmC1z0_8fv2ee0K7477mi6ly'

export const httpConfig = {
    baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
        // 'Access-Control-Allow-Origin': '*'
        // Authorization: authToken ? `Bearer ${authToken}` : ''
    },
    withCredentials: true
}


export const apiUrls = {
    sanctum: '/sanctum/csrf-cookie',
    login: '/api/spa/v1/login',
    loginSubmitOtp: '/api/spa/v1/verify-login-otp',
    loginOldPlatform: '/api/spa/v1/login-old-platform',
    logout: '/api/spa/v1/logout',
    register: '/api/spa/v1/register',
    serviceList: '/api/spa/v1/serviceList',
    forgetPassword: {
        submitEmail: '/api/spa/v1/forgot-password',
        submitMobile: '/api/spa/v1/send-otp',
        submitOtp: '/api/spa/v1/reset-password',
        redendOtp: '/api/spa/v1/resend-otp'
    },
    request: {
        service: '/api/spa/v1/activateService',
        addIp: '/api/spa/v1/add-ip',
        addApiKey: '/api/spa/v1/generateKeys'
    },
    ocr: {
        dashboardStats: '/api/spa/v1/ocr/dashboard',
        datatableTxn: '/api/spa/v1/ocr/transactions',
        recentTxn: '/api/spa/v1/ocr/recent-transactions'
    },
    validationSuite: {
        dashboardStats: '/api/spa/v1/validate/dashboard',
        datatableTxn: '/api/spa/v1/validate/transactions',
        txnCountGraph: '/api/spa/v1/graph/validate-txn-counts'
    },
    fetch: {
        businessType: '/api/spa/v1/getBusinessTypeList',
        categoryList: '/api/spa/v1/categoryList',
        managerList: '/api/spa/v1/account-manager-list',
        stateList: '/api/spa/v1/states'
    },
    update: {
        profile: '/api/spa/v1/profile',
        bank: '/api/spa/v1/update-bank',
        videoKyc: '/api/spa/v1/ekyc/save',
        password: '/api/spa/v1/user/change-password'
    },
    delete: {
        ipwhitelist: '/api/spa/v1/delete-ip'
    },
    user: {
        profile: '/api/spa/v1/profile',
        bank: '/api/spa/v1/userBankInfos',
        wallet: '/api/spa/v1/userWallet',
        userInfo: '/api/spa/v1/userDetails'
    },
    settings: {
        ipwhitelist: '/api/spa/v1/iplist',
        apiKeys: '/api/spa/v1/userServiceKeys'
    },
    dashboard: {
        txnCount: '/api/spa/v1/graph/txn-counts',
        validateWallet: '/api/spa/v1/graph/validate-wallet',
        ocrWallet: '/api/spa/v1/graph/ocr-wallet'
    },
    recent: {
        walletTransactions: '/api/spa/v1/walletTransactions'
    },
    log: {
        loginActivity: '/api/spa/v1/login-activity'
    }
}


export const statusCode = {
    success: '0x0200',
    failed: '0x0202',
    missing: '0x0203'
}

export const appUrls = {
    user: {
        profile: '/user/profile',
        profileUpdate: '/user/profile/update',
        kycUpdate: '/user/profile/kyc',
        setting: '/user/settings'
    }
}


export const serviceLogo = (serviceId) => {

    switch (serviceId) {
        //OCR
        case 'srv_1663916703':
            return { icon: ocr }

        //validation suite
        case 'srv_1652770831':
            return { icon: validationSuite }

        case 'srv_16611613305':
            return { icon: bbps }

        case 'srv_1626077390':
            return { icon: aeps }

        case 'srv_1626077505':
            return { icon: recharge }

        case 'srv_1626077509':
            return { icon: atm }
            
        case 'srv_1626077508':
            return { icon: bbps }
    }

    return ''
}