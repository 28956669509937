// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { http } from '@api/Auth/http'
// import { apiUrls, statusCode } from '@api/Auth/config'


export const getData = createAsyncThunk('datatables/getData', {
    // const response = await http().get(`${apiUrls.ocr.datatableTxn}?page=${params.page}&per_page=${params.perPage}&columns=id&order=desc&search=${params.q}&delay=1`)
    // // console.log('createAsyncThunk', response.data.code)
    // let tableData = []
    // let totalPages = 0
    // if (response.data.code === statusCode.success) {
    //     tableData = response.data.data.data
    //     totalPages = response.data.data.total
    // }
    // // return { allData: response.data.data.data, data: {}, totalPages: response.data.data.total, params }
    // return { allData: tableData, data: {}, totalPages, params }
})


export const datatablesSlice = createSlice({
    name: 'datatables',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
        })
    }
})

export default datatablesSlice.reducer
